import DappConfig from './dapp';
import CONSTANTS from "./constants";

const ChainId = {Sepolia: 11155111, Mainnet: 1}

const stage = process.env.REACT_APP_ENV;


const config = {
  Ethscan: (stage === 'staging' ? 'sepolia.' : '') + 'etherscan.io',
  network: (stage === 'staging' ? 'sepolia' : 'mainnet'),
  supported_chain_ids: (stage === 'staging' ? [ChainId.Sepolia] : [ChainId.Mainnet]),
  chainId: (stage === 'staging' ? ChainId.Sepolia : ChainId.Mainnet),
  networks: {
    MAINNET: ChainId.Mainnet,
    SEPOLIA: ChainId.Sepolia
  },
  networkName: (stage === 'staging' ? 'sepolia.' : 'mainnet'),
  constants: {...CONSTANTS},
  DappConfig: {...DappConfig},
};
export default config;
