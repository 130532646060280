import tokenABI from "../abis/LTDToken.json";
import {Interface} from "ethers";
import {Contract} from "@ethersproject/contracts";
import addresses from "../config/addresses";
// import { useWallet } from '../../Components/WalletContext';  // Import the context hook

// const {useCall, useContractFunction} = require("@usedapp/core");

// const tokenInterface = new Interface(tokenABI);

// const { address, isConnected, provider } = useWallet();  // Access wallet context

// const tokenContract = new Contract(addresses.token, tokenABI, provider);


const UseContractMethod = (contractObj, methodName) => {
    const {state, send, events} = ""
    // const {state, send, events} = useContractFunction(contractObj, methodName, {});
    return {state, send, events};
};


const BalanceOf = async (tokenContract, account) => {
    var balance;
    console.log(account)
    if (account) {
        balance = await tokenContract.balanceOf(account)
    }
    // const balance = await tokenContract.balanceOf(account)
    // const balance = useCall({
    //     contract: tokenContract,
    //     method: "balanceOf",
    //     args: [account],
    // });

    return balance && balance.value ? balance.value[0].toBigInt().toString() : "0";
};

const UserInfo = (contract, account) => {
    const userInfo = ""
    // const userInfo = useCall({
    //     contract: contract,
    //     method: "userInfo",
    //     args: [account],
    // });
    var userObj = {};

    if(userInfo && userInfo.value){
        userObj = {
            stakeAmount: userInfo.value.amount.toBigInt().toString(),
            totalRewards: userInfo.value.rewardDebt.toBigInt().toString(),
            pendingRewards: userInfo.value.pendingRewards.toBigInt().toString(),
            lastClaimTime: userInfo.value.lastClaimTime
        }
    }

    return userObj;
};

const PendingRewards = (stakingContract, account, poolId) => {

    const rewards = ""
    // const rewards = useCall({
    //     contract: stakingContract,
    //     method: "pendingRewards",
    //     args: [poolId, account],
    // });

    return rewards && rewards.value ? rewards.value[0].toBigInt().toString() : 0;
};


export {
    UseContractMethod,
    BalanceOf,
    UserInfo
}
