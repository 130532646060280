const stage =
    process.env.REACT_APP_ENV === 'production'
        ? 'MAINNET' : (
            process.env.REACT_APP_ENV === 'staging'
                ? 'SEPOLIA' : process.env.REACT_APP_ENV.toUpperCase()
        );
export default {
    token: process.env[`REACT_APP_TOKEN_ADDRESS_${stage}`],
    staking: process.env[`REACT_APP_STAKING_ADDRESS_${stage}`],
    claiming: process.env[`REACT_APP_CLAMING_ADDRESS_${stage}`],
};
