import web3 from "web3";

export const shorter = (str, length = 6) => {
    try {
        return `${str.slice(0, length)}...${str.slice(str.length - 4, str.length)}`;
    } catch (err) {
        return '';
    }
};

export const truncate = (str, length = 100) => {
    return str.length > length ? str.substring(0, length) + "..." : str;
}

export const prettyNumber = (nb, string=true, isBig=true, unit='ether') => {
    let finalNumber = nb;

    try {
        if(isBig)
            finalNumber = web3.utils.fromWei(nb, unit);

        // Dynamically determine decimal places based on the size of the number
        const absValue = Math.abs(finalNumber);
        let decimalPlaces = absValue >= 1 ? 2 : absValue >= 0.0001 ? 6 : 8;

        finalNumber = parseFloat(finalNumber).toFixed(decimalPlaces);

        if(string)
            finalNumber = parseFloat(finalNumber).toLocaleString();
    } catch (error) {
        finalNumber = "0"
    }

    return finalNumber;
}
