import {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {BsStack, BsArrowDownSquareFill} from "react-icons/bs";
import {useSystem} from "../../hooks/use-system";
import web3 from "web3";
import addresses from "../../config/addresses";

import tokenABI from "../../abis/LTDToken.json";
import stakingABI from "../../abis/LTDStaking.json";

import {Contract, Interface} from 'ethers'

import {useWallet} from '../../Components/WalletContext';  // Import the context hook


export default function Stake({account, userInfo, setShouldUpdateBalance}) {

    const [staking, isStaking] = useState(true);
    const [stakeAmount, setStakeAmount] = useState("0");
    const [unstakeAmount, setUnstakeAmount] = useState("0");
    const [canStake, setCanStake] = useState(false);
    const [canUnstake, setCanUnstake] = useState(false);
    // const contract = useContract();
    const system = useSystem();

    const [approveState, setApproveState] = useState({status: null, errorMessage: null});
    const [depositState, setDepositState] = useState({status: null, errorMessage: null});
    const [claimState, setClaimState] = useState({status: null, errorMessage: null});
    const [withdrawState, setWithdrawState] = useState({status: null, errorMessage: null});

    const {address, isConnected, provider} = useWallet();  // Access wallet context

    const stakeToken = async () => {
        system.isLoading(true);
        //deposit(web3.utils.toWei((stakeAmount).toString(), 'ether'));

        const signer = await provider.getSigner()
        const stakingContract = new Contract(addresses.staking, stakingABI, signer)
        try {
            const tx = await stakingContract.deposit(web3.utils.toWei((stakeAmount).toString(), 'ether'))
            setDepositState({...depositState, status: 'Mining'});

            const receipt = await tx.wait();
            if (receipt.status === 1) {
                setDepositState({...depositState, status: 'Success'});
            } else {
                setDepositState({...depositState, status: 'Exception'});
            }

            setShouldUpdateBalance(true);

        } catch (error) {
            setDepositState({...depositState, status: 'Exception', errorMessage: error.info.error.message});
        }
    }

    const approveToken = async () => {
        system.isLoading(true);
        system.setMessage(`<BsClockFill/> Please sign the transaction in your wallet...`)
        //approve(addresses?.staking, web3.utils.toWei((stakeAmount).toString(), 'ether'))

        const signer = await provider.getSigner()
        const tokenContract = new Contract(addresses.token, tokenABI, signer)
        try {
            const tx = await tokenContract.approve(addresses.staking, web3.utils.toWei((stakeAmount).toString(), 'ether'))
            setApproveState({...approveState, status: 'Mining'});

            const receipt = await tx.wait();
            if (receipt.status === 1) {
                setApproveState({...approveState, status: 'Success'});
            } else {
                setApproveState({...approveState, status: 'Exception', errorMessage: null});
            }

        } catch (error) {
            setApproveState({...approveState, status: 'Exception', errorMessage: error.info.error.message});
        }
    }

    const claimTokens = async () => {
        system.isLoading(true);
        system.setMessage(`Please sign the transaction in your wallet...`)
        //claim()

        const signer = await provider.getSigner()
        const stakingContract = new Contract(addresses.staking, stakingABI, signer)
        try {
            const tx = await stakingContract.claim()
            setClaimState({...claimState, status: 'Mining'});

            const receipt = await tx.wait();
            if (receipt.status === 1) {
                setClaimState({...claimState, status: 'Success'});
            } else {
                setClaimState({...claimState, status: 'Exception', errorMessage: null});
            }

            setShouldUpdateBalance(true);

        } catch (error) {
            let errorMessage = error.info.error.message;
            if (error.transaction != undefined) {
                errorMessage = "Transaction failed. Check next claim time."
            }
            setClaimState({...claimState, status: 'Exception', errorMessage: errorMessage});
        }
    }

    const unstakeToken = async () => {
        system.isLoading(true);
        system.setMessage(`Please sign the transaction in your wallet...`)

        const signer = await provider.getSigner()
        const stakingContract = new Contract(addresses.staking, stakingABI, signer)
        try {
            const tx = await stakingContract.withdraw(web3.utils.toWei((unstakeAmount).toString(), 'ether'))
            setWithdrawState({...withdrawState, status: 'Mining'});

            const receipt = await tx.wait();
            if (receipt.status === 1) {
                setWithdrawState({...withdrawState, status: 'Success'});
            } else {
                setWithdrawState({...withdrawState, status: 'Exception'});
            }

            setShouldUpdateBalance(true);

        } catch (error) {
            let errorMessage = error.info.error.message;
            // if (error.reason) {
            //     errorMessage = "Reverted With Error: Excess Withdraw";
            // }
            setWithdrawState({...withdrawState, status: 'Exception', errorMessage: errorMessage});
        }
    }

    useEffect(() => {
        setCanStake(stakeAmount > 0);
    }, [stakeAmount])

    useEffect(() => {
        setCanUnstake(unstakeAmount > 0);
    }, [unstakeAmount])

    useEffect(() => {

        if (claimState?.status === 'Exception') {
            system.setError(claimState.errorMessage);
            system.isLoading(false);
        } else if (claimState?.status === 'Mining') {
            system.setMessage(`<BsLockFill /> Transaction Signed! Submitting claim request...`);
        } else if (claimState?.status === 'Success') {
            system.setMessage(`<BsCheckSquareFill /> Your claim is complete!`);
            setTimeout(() => {
                system.isLoading(false);
                system.setMessage('');
            }, 1500)
        }

    }, [claimState])

    useEffect(() => {

        if (approveState?.status === 'Exception') {
            system.setError(approveState.errorMessage);
            system.isLoading(false);
        } else if (approveState?.status === 'Mining') {
            system.setMessage(`<BsLockFill /> Transaction Signed! Giving Approval for staking ${stakeAmount} LTD...`);
        } else if (approveState?.status === 'Success') {
            system.setMessage(`<BsCheckSquareFill /> Your stake amount is approved!... <BsClockFill /> Please sign transfer transaction!`);
            stakeToken();
        }

    }, [approveState])

    useEffect(() => {

        if (depositState?.status === 'Exception') {
            system.setError(depositState.errorMessage);
            system.isLoading(false);
        } else if (depositState?.status === 'Mining') {
            system.setMessage(`<BsLockFill /> Transaction Signed! Staking ${stakeAmount} LTD...`);
        } else if (depositState?.status === 'Success') {
            system.setMessage(`<BsCheckSquareFill />  Your stake is completed!`);
            setStakeAmount("0")
            setTimeout(() => {
                system.isLoading(false);
                system.setMessage('');
            }, 1500)

        }

    }, [depositState])

    useEffect(() => {

        if (withdrawState?.status === 'Exception') {
            system.setError(withdrawState.errorMessage);
            system.isLoading(false);
        } else if (withdrawState?.status === 'Mining') {
            system.setMessage(`<BsLockFill /> Transaction Signed! Unstaking ${unstakeAmount} LTD...`);
        } else if (withdrawState?.status === 'Success') {
            system.setMessage(`<BsCheckSquareFill />  Your withdraw is completed!`);
            setUnstakeAmount("0")
            setTimeout(() => {
                system.isLoading(false);
                system.setMessage('');
            }, 1500)

        }

    }, [withdrawState])


    return (
        <Container className={'text-center align-items-center justify-content-center'}>
            {account ? (
                <>
                    <Row>
                        <Col className={'d-flex justify-content-center mb-3'}>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch"
                                       id="flexSwitchCheckChecked" checked={!staking}
                                       onChange={() => isStaking(!staking)}/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                                    Want to Claim?
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'d-flex justify-content-center'}>

                            <div className={'stakeInputWrapper'}>
                                <div>
                                    {staking &&
                                        <input type={'number'} className={'form-control brandInput'} value={stakeAmount}
                                               onChange={(e) => {
                                                   setStakeAmount(e.target.value);
                                               }}/>
                                    }
                                    {/*<Button className={'maxBtn'}>Max</Button>*/}
                                    {staking && <Button style={{
                                        padding: '2rem 1.7rem'
                                    }} className={'brandBtn attached'} disabled={!canStake}
                                                        onClick={approveToken}>Stake <BsStack
                                        className={'ms-2'}
                                        size={25}/></Button>
                                    }
                                </div>
                                <div>
                                    {staking &&
                                        <input type={'number'} className={'form-control brandInput'}
                                               value={unstakeAmount}
                                               onChange={(e) => {
                                                   setUnstakeAmount(e.target.value);
                                               }}/>
                                    }


                                    {staking && <Button style={{
                                        padding: '2rem 1rem'
                                    }} className={'brandBtn attached'} disabled={!canUnstake}
                                                        onClick={unstakeToken}>Unstake <BsStack
                                        className={'ms-2'}
                                        size={25}/></Button>
                                    }
                                </div>

                                <div>
                                    {!staking && <Button style={{
                                        padding: '2rem 1rem'
                                    }} className={'brandBtn full'} disabled={userInfo?.pendingRewards == 0}
                                                         onClick={claimTokens}>Claim <BsArrowDownSquareFill
                                        className={'ms-2'}
                                        size={25}/></Button>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <p>Please connect wallet in order to stake your LTDs</p>
            )}
        </Container>
    )
}
