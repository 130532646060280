import React, { createContext, useContext, useState, useEffect } from 'react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider } from 'ethers';

// 1. Your WalletConnect Cloud project ID
const projectId = '1fe54ebb553c51a0303f0c095a6cfd7e'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

// 3. Create a metadata object
const metadata = {
  name: 'LTD Staking',
  description: 'LTD Staking',
  url: 'https://localhost:3000', // origin must match your domain & subdomain
  // icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

// Create the context
const WalletContext = createContext();

// WalletProvider component that will wrap the app
export function WalletProvider({ children }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [provider, setProvider] = useState(null);

  // Set up the ethers provider when walletProvider is available
  useEffect(() => {
    if (walletProvider) {
      console.log('Setting up ethers provider from walletProvider');  // Debugging log
      const ethersProvider = new BrowserProvider(walletProvider);
      setProvider(ethersProvider);  // Set the provider in state
    } else {
      console.log('walletProvider is not available');  // Debugging log
    }
  }, [walletProvider]);

  return (
    <WalletContext.Provider value={{ address, chainId, isConnected, provider }}>
      {children}
    </WalletContext.Provider>
  );
}

// Custom hook to use the wallet context
export function useWallet() {
  return useContext(WalletContext);
}
