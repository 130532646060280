

const ChainId = {Sepolia: 11155111, Mainnet: 1}
const Sepolia = 11155111
const Mainnet = 1

const stage = process.env.REACT_APP_ENV;

console.log(`Network: ${stage === 'staging' ? 'SEPOLIA' : 'MAINNET'}`);

var readUrlsDict = {};
if(stage === 'staging'){
    readUrlsDict[ChainId.Sepolia] = `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;
}else{
    readUrlsDict[ChainId.Mainnet] = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;
}

const DappConfig = {
    readOnlyChainId: stage === 'staging' ? ChainId.Sepolia : ChainId.Mainnet,
    readOnlyUrls: readUrlsDict,
    pollingInterval: 3000,
    networks: [stage === 'staging' ? Sepolia : Mainnet],
    notifications: {
      checkInterval: 100,
      expirationPeriod: 10000,
    },
    autoConnect: true
}

export default DappConfig;
