import './App.scss';
import {BrowserRouter, Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Home from "./pages/Home";
import {WalletProvider} from './Components/WalletContext';
import {ImageBackground, StyleSheet, Dimensions} from 'react-native';
import Header from "./Components/Header";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {

    return (
        <div className="App">
            <header className="App-header">
            </header>
            <WalletProvider>
                <BrowserRouter>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>LivingTheDream - Staking</title>
                        <meta property="og:type" content="website"/>
                        <meta name="author" content=""/>
                    </Helmet>
                    <Header/>
                    <Home/>
                </BrowserRouter>
            </WalletProvider>
        </div>


    );
}

export const styles = StyleSheet.create(
    {
        backgroundImage: {
            position: 'relative',
            left: 0,
            top: 0,
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
            backgroundSize: 'cover',
            flex: 1
        },
    })

export default App;
