import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import { Container, Navbar } from "react-bootstrap";
import { useSystem } from "../../hooks/use-system";

export default function Header() {

    const system = useSystem();
    let navigate = useNavigate();

    return (
        <Navbar collapseOnSelect expand="xl" variant="light" fixed={'top'} style={{
            backgroundColor: 'rgba(0,0,0,0.7)'
        }}>
            <Container>
                <Navbar.Brand href="/#home" onClick={(e) => {
                    e.preventDefault()
                    navigate("/#home");
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }}>
                    <img src={logo} className={'logo'} alt={"LTD Logo"} width={150} />
                </Navbar.Brand>
                <Navbar id="responsive-navbar-nav" className={'justify-content-end'}>
                    <w3m-button />
                </Navbar>
            </Container>
        </Navbar>
    )

}
