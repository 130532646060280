import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import Header from "../../Components/Header";
import Stake from "../../Components/Stake";
import {useSystem} from "../../hooks/use-system";
import {BalanceOf, UserInfo, PendingRewards} from "../../hooks/use-dapp";
import {useContract} from "../../hooks/use-contract";
import "./style.scss"
import {prettyNumber} from "../../utils/string";
import {BsExclamationTriangleFill} from "react-icons/bs";

import {useWallet} from '../../Components/WalletContext';  // Import the context hook

import React, {useState, useEffect} from 'react';
import {BrowserProvider, Contract, formatUnits, WebSocketProvider} from 'ethers'

import addresses from '../../config/addresses';
import tokenABI from "../../abis/LTDToken.json";
import stakingABI from "../../abis/LTDStaking.json";

export default function Home() {

    const system = useSystem();

    const {address, isConnected, provider} = useWallet();  // Access wallet context
    const account = address

    const [tokenBalance, setTokenBalance] = useState(null);
    const [stakeAmount, setStakeAmount] = useState(null);
    const [pendingRewards, setPendingRewards] = useState({KNINE: 0, SHIB: 0, LTD: 0});
    const [shouldUpdateBalance, setShouldUpdateBalance] = useState(false);

    // Function to fetch the token balance
    async function fetchTokenBalance() {
        if (provider && address) {
            try {
                const tokenContract = new Contract(addresses.token, tokenABI, provider)
                const balance = await tokenContract.balanceOf(address)
                setTokenBalance(balance)
            } catch (error) {
                console.error('Error fetching token balance:', error);
            }
        }
    }

    async function fetchStakeAmount() {
        if (provider && address) {
            try {
                const stakingContract = new Contract(addresses.staking, stakingABI, provider)
                const user = await stakingContract.userInfo(address)
                setStakeAmount(user.amount)
            } catch (error) {
                console.error('Error fetching token balance:', error);
            }
        }
    }

    async function fetchPendingRewards() {
        if (provider && address) {
            try {
                const stakingContract = new Contract(addresses.staking, stakingABI, provider)
                const pendingKNINE = await stakingContract.pendingRewards(0, address)
                const pendingSHIB = await stakingContract.pendingRewards(1, address)
                const pendingLTD = await stakingContract.pendingRewards(2, address)

                setPendingRewards({...pendingRewards, KNINE: pendingKNINE, SHIB: pendingSHIB, LTD: pendingLTD});
            } catch (error) {
                console.error('Error fetching token balance:', error);
            }
        }
    }

    // Call fetch functions when wallet is connected
    useEffect(() => {
        if (isConnected && address && provider) {
            const fetchData = async () => {
                try {
                    await fetchTokenBalance();  // Call your async function to fetch token balance
                    await fetchStakeAmount();   // Call your async function to fetch stake amount
                    await fetchPendingRewards();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();  // Call the async function
            setShouldUpdateBalance(false);
        }
    }, [isConnected, address, provider, shouldUpdateBalance]);  // Re-fetch data when connection state changes


    return (
        <Container className={'pageContainer vh-100 text-white d-md-flex align-items-center d-flex'}>
            <div id={'loadingPopup'} className={system.loading ? 'show' : ''}>
                {system.loading && <div className="spinner-border text-white" role="status"></div>}
                <br/>
                <div dangerouslySetInnerHTML={{__html: system.message}}></div>
            </div>
            {system.loading && <div id={'loadingBack'}></div>}
            {system.messageType === 'error' && system.message && <div style={{
                background: '#ff682a', color: '#FFF', marginBottom: '2rem', padding: '.5rem',
                position: 'relative', textAlign: 'left'
            }}><BsExclamationTriangleFill size={25}/> {system.message} <span className={'closeMessage'} onClick={() => system.setError('')}>X</span></div>}
            <Container>
                <Row style={{marginTop: '5rem'}}>
                    <Col xs={6} className={'mb-2'}>
                        <h4 className={'text-start'}>Token Balance</h4>
                        <div className={'balanceBox small'}>
                            {account ? <>{prettyNumber(tokenBalance)} <span> LTD</span></> :
                                <span>Connect your wallet</span>}
                        </div>
                    </Col>
                    <Col xs={6} className={'mb-2'}>
                        <h4 className={'text-start'}>Staked Balance</h4>
                        <div className={'balanceBox small'}>
                            {account ? <>{prettyNumber(stakeAmount, true)} <span> LTD</span></> :
                                <span>Connect your wallet</span>}
                        </div>
                    </Col>
                    <Col xs={12} className={'mb-2'}>
                        <h4 className={'text-start'}>Rewards</h4>
                        <div className={'balanceBox small'}>
                            {account ? (
                                <div className={'w-100'}>
                                    {
                                        (pendingRewards.LTD == 0 && pendingRewards.SHIB == 0 && pendingRewards.KNINE == 0) ?
                                            <div>
                                                <p>
                                                    <span>No Pending Rewards</span>
                                                </p>
                                            </div> : (
                                                <div className={'d-flex flex-row align-items-center justify-content-around w-100'}>
                                                    {pendingRewards.KNINE != 0 && (
                                                        <p>
                                                            {prettyNumber(pendingRewards.KNINE, false, true)} <span>KNINE</span>
                                                        </p>
                                                    )}

                                                    {pendingRewards.SHIB != 0 && (
                                                        <p>
                                                            {prettyNumber(pendingRewards.SHIB, false, true)} <span>SHIB</span>
                                                        </p>
                                                    )}

                                                    {pendingRewards.LTD != 0 && (
                                                        <p>
                                                            {prettyNumber(pendingRewards.LTD, true)} <span>LTD</span>
                                                        </p>
                                                    )}
                                                </div>

                                            )
                                    }
                                </div>
                            ) : (
                                <span>Connect your wallet</span>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className={'mx-auto mt-2'}>
                        <Stake account={account} setShouldUpdateBalance={setShouldUpdateBalance}/>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
